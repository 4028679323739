import React from 'react';
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Footer/Footer';
import css from './Coming.module.css';


const Coming = () => {
  return (
    <div className={css.Container}>
      <h2>EventEaze</h2>  
      <h4>We are currently working on something awesome. Stay tuned!</h4>
      <h1>COMING SOON</h1>
    </div>
  )
}

export default Coming