import React from "react";
import css from "./Events.module.css";

const Events = (props) => {
  const imageData = props.data;
  return (
    <div className={css.eventCard}>
      <div className={css.card2}>
        {imageData.img && (
          <img src={imageData.img} className="card-img-top" alt="..." />
        )}
        {
          imageData.title && (
            <h4>{imageData.title}</h4>
          )
        }
      </div>
    </div>
  );
};

export default Events;
