import img1 from "../resources/1.jpg";
import bands from "../resources/Bands.jpg";
import decor from "../resources/decor.jpg";
import florist from "../resources/florist.jpg";
import haldi from "../resources/haldi.jpg";
import lights from "../resources/lights.jpg";
import pandit from "../resources/pandit.jpg";
import tents from "../resources/tents.jpg";
import venue from "../resources/venue.jpg";
import wedding from '../resources/wedding.jpeg';

export const HeroCardData = [
  {
    title: "Venue",
    sub_title: "The venue is where dreams become memories.",
    description:"Venues are the heart of any event, setting the stage for memorable moments. But choosing the perfect one can feel overwhelming. That's where Event MarketPlace steps in. We simplify the process, connecting you with trusted Venue providers who understand your needs. Whether it's a wedding or a corporate event, our platform offers a curated list of top venues in Hyderabad. With just a few clicks on our user-friendly website or mobile app, you can explore options and book with ease. Let us assist you in crafting memories that will last a lifetime.",
    img: venue,
  },

  {
    title: "FLORISTS",
    sub_title:"Flowers speak a silent language of beauty.",
    description: "Welcome to our exquisite world of floral wonders! At [Your Business Name], we understand that every event deserves to be adorned with the beauty and fragrance of nature's finest blooms. Our team of talented florists is dedicated to crafting breathtaking arrangements that capture the essence of your special moments.Whether it's a wedding, a party, or any celebration, we've got the perfect floral arrangements to match your style. From classic roses to trendy succulents, we offer a wide range of fresh flowers sourced from top growers.We pay attention to every detail to ensure your flowers look stunning. Let us help you create unforgettable moments with our gorgeous floral designs. Contact us today and let's make your event blossom with joy!",
    img: florist,
  },

  

  {
    title:"PANDIT & PRIEST",
    sub_title:"Dive into the realm of spirituality and tradition with our Pandit & Priest services.",
    description:"Our experienced pandits and priests bring wisdom, devotion, and blessings to ceremonies of all kinds, ensuring that each ritual is performed with reverence and authenticity.Whether you're planning a wedding, a housewarming ceremony, or a religious festival, our pandits and priests are well-versed in the rituals and customs of various traditions. With their expertise and guidance, you can rest assured that your ceremonies will be conducted with the utmost care and precision.Beyond performing rituals, our pandits and priests serve as spiritual guides, offering counsel and blessings to help you navigate life's journey. Let our pandits and priests be the guardians of tradition and spirituality at your next ceremony, ensuring that every moment is imbued with sacredness and significance.",
    img:pandit,
  },
  
  {
    title:"BANDS",
    sub_title:"Step right into our band's awesome zone, where we bring the magic of live music to your events! ",
    description:"Whether you're planning a wedding, corporate event, or a birthday party, our diverse selection of bands is sure to have the perfect sound to elevate your celebration.From soulful jazz bands that set the mood for a romantic evening to high-energy cover bands that keep the dance floor packed all night long, we have something for every taste and occasion. Kick back and unwind, and let our bands take your event to the next level with their incredible talent, infectious energy, and undeniable charisma. With our easy booking process, you can find the perfect band for your event in just a few clicks. Get ready to dance, and make memories that you'll cherish forever with our amazing lineup of bands!",
    img:bands,
  },
  

];

export const extradata= [
  {
    title:"LIGHTS & SOUNDS",
    sub_title:"In the right light, at the right time, everything is extraordinary.",
    description:"Welcome to the Lights and Sounds extravaganza! where we bring your event to life with a captivating blend of illumination and audio magic! Whether you're planning an intimate gathering or a grand celebration, our array of lighting and sound solutions will set the perfect ambiance and elevate every moment.Illuminate your venue with a dazzling array of LED lights, create mesmerizing visual effects with professional-grade spotlights, and immerse your guests in crystal-clear audio with our cutting-edge sound systems. Our comprehensive selection of equipment and expert technicians ensure seamless integration and flawless execution, leaving you free to focus on enjoying your event.From weddings and concerts to corporate functions and beyond, our Lights and Sounds service provides everything you need to transform any space into an memorable experience. Let us help you create memories that shine bright and resonate long",
    img:lights,
  },
  {
    title:"TENT HOUSES",
    sub_title:"Discover the offerings of Tent House suppliers, your one-stop destination for all your event shelter needs! ",
    description:"Whether you're planning an outdoor wedding, a corporate retreat, or a backyard party, from elegant marquees and spacious canopies to cozy tipis and sturdy frame tents, our diverse selection of high-quality tents ensures your event is resounding success.But we don't stop there – our comprehensive range of accessories, including flooring, Seating, lighting, heating, and decor, allows you to customize your outdoor space and create a comfortable and inviting atmosphere for your guests. With our expert team on hand to assist with setup and takedown, you can rest assured that your event will be executed seamlessly from start to finish. Whether you're planning a wedding, corporate function, or backyard party, let us help you turn your outdoor vision into a reality. Explore our Tent House Suppliers category today and elevate your event to new heights!",
    img:tents,
  },
  {
    title:"BHAJAN & KIRTAN",
    sub_title:"Let the melodies of devotion lift your spirits and soothe your soul.",
    description:"Welcome to our Bhajan & Kirtan category, where spirituality meets music in a harmonious blend of devotion and rhythm. Dive into the enchanting world of Bhajans and Kirtans, where the heart finds solace and the mind attains tranquility.Whether you seek traditional Bhajans sung in classical ragas or contemporary Kirtans infused with modern interpretations, our collection offers a diverse range of spiritual music to uplift your soul. Immerse yourself in the divine melodies that echo through the ages, connecting hearts and minds across cultures and continents.Join us on this musical pilgrimage where every note resonates with the divine, guiding you towards inner peace and spiritual fulfillment. Let the melodies of devotion envelop you, leading you closer to the essence of your being and the source of all creation.",
    img: img1,
  },
  {
    title:"COOKS",
    sub_title:"Cooking is love made visible.",
    description:"At Event Marketplace, we bring the joy of personalized dining right to your doorstep with our Cook service. Our expert chefs specialize in creating drool-worthy dishes tailored to your preferences, ensuring a culinary experience like no other.From classic comfort foods to gourmet specialties, our chefs use the finest ingredients and culinary techniques to craft delicious meals that delight your senses. Whether you're hosting an intimate gathering or simply craving a tasty homemade meal, our chefs are here to make your culinary dreams a reality.Say goodbye to the stress of meal planning and cooking, and let us handle everything from start to finish. With Event Marketplace, you can sit back, relax, and savor every bite knowing that you're in good hands.",
  },
  {
    title:"WHOLESALE SUPPLIERS",
    sub_title:"Enter the realm of convenience and quality with our wholesale suppliers! ",
    description:"We understand the importance of sourcing products efficiently for your event and that's why we've curated a diverse range of wholesale suppliers to meet your every need. Whether you're in search of refreshing mineral water, sacred Puja items, or fresh vegetables, we've got you covered. Our suppliers are committed to providing top-notch products at competitive prices, ensuring that your event thrives without any hassle. With our platform, you can browse through a variety of options, place orders seamlessly, and have your products delivered straight to your doorstep. Say goodbye to stress and hello to convenience with our trusted wholesale suppliers.",
  },
  {
    title: "Caterers",
    sub_title:"Delight your guests with mouthwatering dishes from our expert caterers!",
    description: "Experience culinary excellence with our Caterer service. Make your event extra special with delicious food catered just for you. Whether it's a small get-together or a big party, our caterers are here to make sure everyone leaves happy and full. Choose from a variety of cuisines like South Indian, North Indian, Chinese, Italian, and more – whatever you're craving, we've got it covered! From dosas to dumplings, pasta to paneer tikka, our menu options are endless. Sit back, relax, and let us take care of the cooking and serving while you enjoy your event with friends and family. Hosting has never been this easy – book your caterer today and get ready for a feast to remember!",
    img: img1,
  },
  {
    title:"PHOTOGRAPHY",
    sub_title:"Photography takes an instant out of time, altering life by holding it still.",
    description:"Welcome to our photographers' corner, where moments become memories captured in pixels. Our skilled photographers are ready to transform your events into timeless treasures. Whether it's a wedding, a birthday bash, or a corporate gathering, our photographers have the keen eye and expertise to document every smile, tear, and laughter.With a blend of creativity and technical prowess, our photographers ensure that each frame speaks volumes about the essence of your event. From candid shots that capture the raw emotions to meticulously planned compositions that showcase the grandeur of the occasion, our team is committed to delivering excellence.We understand that every event is unique, and so are your photography needs. Whether you prefer traditional poses or contemporary styles, our photographers work closely with you to understand your vision and bring it to life. Let us turn your special moments into everlasting memories with our professional photography services.",
  
  },
  {
    title:"FUNERAL SERVICES",
    sub_title:"A funeral is not a day in a lifetime; it's a lifetime in a day.",
    description:"In times of loss and grief, our funeral services provide comfort and support to families as they honor the lives of their loved ones. We understand the delicate nature of arranging a funeral, so let us help you create a dignified farewell that reflects the unique essence of your loved one's life.From traditional funeral ceremonies to contemporary celebrations of life, we tailor our services to meet the cultural, religious, and personal preferences of the families we assist. Our experienced staff handles every detail with care and professionalism, ensuring that your loved one's final farewell is dignified and memorable.At this difficult time, let us ease the burden of funeral arrangements so you can focus on honoring the legacy and memories of your loved one. Our commitment to exceptional service and unwavering support extends beyond the funeral service, as we stand by you in your journey of healing and remembrance.",
  },
]
