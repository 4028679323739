import baker from "../resources/bakers.jpeg";
import pujas from "../resources/pujas.jpeg";
import bachelors from "../resources/bachelors.avif";
import venue from "../resources/venue.jpeg";
import tents from "../resources/tents.jpg";
import aniversary from "../resources/aniversary.jpeg";
import cooks from "../resources/cooks.webp";
import caters from "../resources/caters.avif";
import wholesale from "../resources/wholesale.jpeg";
import photographers from "../resources/photographers.jpeg";
import babyshower from "../resources/babyshower.jpg";
import housewarming from "../resources/housewarming.webp"

export const data = [
    {
        title:"BAKERS",
        img: baker,
    },

    {
        title:"VENUE",
        img: venue,
    },

    {
        title:"TENTS",
        img:tents,
    },

    {
        title:"COOKS",
        img: cooks,
    },

    {
        title:"CATERS",
        img: caters,
    },

    {
        title:"WHOLESALE",
        img: wholesale,
    },

    {
        title:"PHOTOGRAPHERS",
        img:  photographers,
    },

   
]

export const mainevents = [
    {
        title:"PUJA",
        img: pujas,
    },
    {
        title:"ANIVERSARY",
        img:aniversary,
    },
    {
        title:"BACHELORETTE PARTY",
        img:  bachelors,
    },
    {
        title:"HOUSE WARMING",
        img: housewarming ,
    },
    {
        title:"BABY SHOWER",
        img: babyshower,
    }
]