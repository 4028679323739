import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Common/Navbar/Navbar";
import AboutUs from "./Components/AboutUs/AboutUs";
import SignIn from "./Components/SignIn/SignIn";
import Cart from "./Components/Cart/Cart";
import Offers from "./Components/Offers/Offers";
import Reviews from "./Components/Reviews/Reviews";
import FAQs from "./Components/FAQs/FAQs";
import Blogs from "./Components/Blogs/Blogs";
import ContactExpert from "./Components/Contact Expert/ContactExpert";
import Footer from "./Components/Footer/Footer";
import CarouselMainPage from "./Components/Common/Carousel/CaourselMainPage";
import Home from "./Components/Home/Home";
import VenueCard from "./Components/Categories/Venue/VenueCard";
import Coming from "./Components/Coming/Coming";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          {/* <Route path="/" element={<CircleCardSlider sliderData={HeroCardData} />} /> */}
          {/* <Route path="/about-us" element={<AboutUs />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/venue" element={<VenueCard />} /> */}

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/signIn" element={<Coming />} />
          <Route path="/cart" element={<Coming />} />
          <Route path="/offers" element={<Coming/>} />
          <Route path="/reviews" element={<Coming />} />
          <Route path="/faqs" element={<Coming />} />
          <Route path="/blogs" element={<Coming/>} />
          <Route path="/venue" element={<Coming />} />
        <Route path="/" element={<Home/>}/>
        <Route path="/comingsoon" element={<Coming/>}/>
        <Route path="/tent-houses" element={<Coming />} />
        <Route path="/florist" element={<Coming />} />
        <Route path="/cooks" element={<Coming />} />
        <Route path="/caters" element={<Coming />} />
        <Route path="/localbands" element={<Coming />} />
        <Route path="/funeral-services" element={<Coming />} />
        <Route path="/cakes-bakers" element={<Coming />} />
        <Route path="/wholesale-services" element={<Coming />} />
        <Route path="/lights-sounds" element={<Coming />} />
        <Route path="/bhajan-keerthan" element={<Coming />} />
        <Route path="/pandit-priest" element={<Coming />} />
        <Route path="/photographers" element={<Coming />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
