import React from 'react';
import Slider from "react-slick";
import css from './Carousel5.module.css';
import Events from '../Events/Events';



const CarouselFive = (props) => {

    var settings = {
      dots: true,
      infinite: true,
      speed: 3000,
      navs: true,
      autoplay: true,
      autoplaySpeed:3000,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

  return (
    <div className={css.slidercontainer}>
       <div className={css.Maincontainer}>
            <Slider {...settings}>
              {props.sliderData &&
                props.sliderData.map((each) => {
                  return (
                    <div>
                      <Events data={each} />
                    </div>
                  );
                })}
            </Slider>
          </div>
    </div>
  )
}


export default CarouselFive


