import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h1>Why Choose EvenEaze ?</h1>
      <div className="features-container">
        <div className="feature-card">
          <h2>COMPREHENSIVE VENDOR NETWORK</h2>
          <p>Say goodbye to the hassle of searching for different vendors across the city. EventEaze brings together a myriad of vendors, ranging from decorators and caterers to photographers and entertainers, all under one virtual roof.</p>
        </div>
        <div className="feature-card">
          <h2>TAILORED FOR EVERY OCCASION</h2>
          <p>No matter the nature or scale of your event, EventEaze caters to a wide spectrum of occasions. From intimate birthday parties to grand corporate galas, our platform ensures that you find the perfect vendors to meet your specific requirements.</p>
        </div>
        <div className="feature-card">
          <h2>SIMPLICITY AT ITS CORE</h2>
          <p>Our primary goal is to make event planning simple and stress-free. Navigate through our user-friendly interface to discover a world of vendors, each committed to delivering exceptional services that align with your vision.</p>
        </div>
        <div className="feature-card">
          <h2>QUALITY ASSURANCE</h2>
          <p>We take the guesswork out of event planning by curating a selection of vendors known for their excellence in service. Rest assured, every vendor featured on Event Eaze is chosen for their professionalism, reliability, and dedication to making your event unforgettable.</p>
        </div>
        <div className="feature-card">
          <h2>PERSONALIZED EXPERIENCE</h2>
          <p>Your event is unique, and so are your preferences. EventEaze allows you to customize your search, ensuring that you find vendors who resonate with your style and budget, making your event truly reflective of your vision.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;