import React from 'react';
import styles from './ContactExpert.module.css';

const ContactExpert = () => {
  return (
    <section className={styles.contactExpert}>
      <div className={styles.icon}>
    
      </div>
      <h2 className={styles.title}>Contact the expert for free</h2>
      <p className={styles.description}>
        We'd love to help you! 24x7.
      </p>
      <a href="tel:8688053286" className={styles.callButton}>
        <span className={styles.phoneIcon}>📞</span>
        Call +91 8688053286
      </a>
    </section>
  );
};

export default ContactExpert;