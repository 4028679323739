import React from 'react';
import css from './CarouselMainPage.module.css';
import Slider from "react-slick";
import Images from "../Images";

const CaourselMainPage = (props) => {

    const settings = {
        dots: true,
        infinite: true,
        navs: true,
        autoplay:true,
        speed: 5000,
        autoplaySpeed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
        <>
      <section>
        {props.sliderHeading && <h6>{props.sliderHeading}</h6>}
        {props.sliderData && (
          <div className={css.Maincontainer}>
            <Slider {...settings}>
              {props.sliderData &&
                props.sliderData.map((each) => {
                  return (
                    <div>
                      <Images data={each} />
                    </div>
                  );
                })}
            </Slider>
          </div>
        )}
      </section>
    </>
  )
}

export default CaourselMainPage