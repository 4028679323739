import React from 'react';
import css from './Home.module.css';
import CarouselMainPage from '../Common/Carousel/CaourselMainPage';
import { HeroCardData } from '../../StaticData/HomePageData';
import ContactExpert from '../Contact Expert/ContactExpert';
import CarouselFive from '../Common/Carousel/CarouselFive';
import { data } from '../../StaticData/EventsData';

const Home = () => {
  return (
    <div className={css.container}>
     
      <CarouselMainPage sliderData={HeroCardData} />
      <div className={css.content}>
        <h1>EventEaze</h1>
        <h4>Your Vision Our Mission For Event Perfection!</h4>
      </div>
      <h3>Popular Services</h3>
      <CarouselFive sliderData={data} />
      <ContactExpert />
    </div>
  )
}

export default Home;